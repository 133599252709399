<template>
  <footer style="margin: 0 0;background-color: #0e0e0e !important;" class="footer " v-if="showFooter || dirPage">
    <!--          style="padding-left: 35%;align-content: center;">-->
    <CookieLaw buttonClass="btn btn-sm btn-primary" theme="black"></CookieLaw>
    <div class="row" style="border-top: 1px solid mediumaquamarine;padding: 20px;background-color: #1c1c1c;opacity: 100 !important;">

      <div class="col-sm-3 col-md-3">

        <!--        <ul class="nav navbar" style=" z-index: 10">-->
        <ul style="z-index: 10;">
          <h5 class="section-header">TULUM</h5>
          <li class="nav-item">
            <router-link :to="{ path: '/#event-line' }">
              Event Line
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Clubs' }">Nightclubs</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'BeachClubs' }">Beachclubs</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'PartyCalendar' }">Party Calendar</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Brands' }">Production Brands</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'TulumNye2024' }">New Year Festival</router-link>
          </li>
        </ul>
      </div>
      <div class="col-sm-3 col-md-3">
        <ul>
          <h5 class="section-header">TOP CLUBS</h5>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/bagatelle-tulum' }">Bagatelle</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/bestiario-tulum' }">Bestiario</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/bonbonniere-tulum' }">Bonbonniere</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/chambao-tulum' }">Chambao</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/confessions-tulum' }">Confessions</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/gitano-tulum' }">Gitano</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/gitano-beach-tulum' }">Gitano Beach</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/ilios-restaurant-tulum' }">Ilios</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/mamazzita-tulum' }">Mamazzita</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/mia-beach-club-tulum' }">Mia Tulum</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/parole-tulum' }">Parole</router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/papaya-playa' }">Papaya Playa</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/rosa-negra-tulum' }">Rosa Negra</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/taboo-tulum' }">Taboo Tulum</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/tantra-tulum' }">Tantra Tulum</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/tora-tulum' }">Tora Tulum</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/tulum-clubs/vagalume-tulum' }">Vagalume Tulum</router-link>
          </li>
        </ul>
      </div>
      <div class="col-sm-3 col-md-3">
        <ul>
          <h5 class="section-header">PRODUCTION BRANDS</h5>
          <li class="nav-item">
            <router-link :to="{ path: '/brands/afterlife-tulum' }">Afterlife</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/brands/ancestral-soul-tulum' }">Ancestral Soul</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/brands/ants-tulum' }">Ants</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/brands/day-zero-tulum' }">Day Zero</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/brands/keinemusik-tulum' }">Keinemusik</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/brands/mayan-warrior-tulum' }">Mayan Warrior</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/brands/mystical-sunset-tulum' }">Mystical Sunset</router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link :to="{ path: '/brands/piknik-tulum' }">PIKNIK</router-link>-->
<!--          </li>-->
          <li class="nav-item">
            <router-link :to="{ path: '/brands/set-underground-tulum' }">SET Underground</router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link :to="{ path: '/brands/sinner-tulum' }">Sinner</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <router-link :to="{ path: '/brands/sos-save-our-souls-tulum' }">SOS</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <router-link :to="{ path: '/brands/summerians-ibiza-tulum' }">Summerians</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <router-link :to="{ path: '/brands/ten-ibiza-tulum' }">Ten Ibiza</router-link>-->
<!--          </li>-->
          <li class="nav-item">
            <router-link :to="{ path: '/brands/zamna-tulum' }">Zamna Tulum</router-link>
          </li>
        </ul>
      </div>
      <div class="col-sm-3 col-md-3">
        <ul>
          <h5 class="section-header">RESOURCES</h5>

          <li class="nav-item">
            <router-link :to="{ name: 'TulumDjSchedule' }">DJ Schedule</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Blog' }">Blog</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Community' }">Community</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }">Search</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'TulumNightLife' }">Nightlife Guide</router-link>
          </li>
          <li class="nav-item">
            <a href="https://www.instagram.com/tulum.party">Instagram</a>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Faq' }"> FAQS</router-link>
          </li>
          <li class="nav-item">
            <a
              target="_blank"
              rel="noopener"
              href="https://imgix.cosmicjs.com/b71976d0-e281-11eb-b4d6-4f771ba4265e-TulumPartyAviso-Privacidad.pdf"
            >
              Aviso Privacidad
            </a>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link :to="{ name: 'About' }"> About Us</router-link>-->
<!--          </li>-->

        </ul>
      </div>

      <div class="col-sm-3 col-md-3">
        <ul>
          <h5 class="section-header">
            <router-link :to="{ path: '/cancun-clubs' }">
              CANCUN
            </router-link>
          </h5>
          <li class="nav-item">
            <router-link :to="{ path: '/cancun-clubs/coco-bongo-cancun' }">
              Coco Bongo Cancun
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/cancun-clubs/confessions-cancun' }">
              Confessions Cancun
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/cancun-clubs/taboo-cancun' }">
              Taboo Cancun
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-sm-3 col-md-3">
        <ul>
          <h5 class="section-header">OTHER LOCATIONS</h5>
          <li class="nav-item">
            <a href="https://www.cabo.party">LOS CABOS</a>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'IslaMujeres' }"> Isla Mujeres</router-link>
          </li>

        </ul>

      </div>
      <div class="col-sm-3 col-md-3">
        <ul>
          <h5 class="section-header">OTHER SERVICES</h5>
          <li class="nav-item">
            <router-link :to="{ name: 'ConciergeServices' }"> Concierge Services</router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'WeddingPlanning' }"> Wedding Planners</router-link>
          </li>

        </ul>

      </div>


      <div class="card text-center centered" style="padding: 30px">
        <img v-lazy="tulumPartyLogo" height="15" width="105" class="centered" alt="tulum party logo">
        <small style="font-weight: normal;font-size: 10px">PLAN A | SIDE B</small>
      </div>
      <br/>
      <br/>

    </div>
  </footer>
</template>
<script>
import CookieLaw from 'vue-cookie-law'
import {mapState} from "vuex";

export default {
  components: {CookieLaw},
  computed:
    mapState({
      showFooter: (state) => state.showFooter,
    })
  ,
  props: ['dirPage'],
  data: () => {
    return {
      tulumPartyLogo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png?w=105&h=15&fit=clamp',
      insta:"https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png?h=17&w=17&auto=format"
    };
  },
};
</script>

